<div class="notification-container">
  <div class="notification-center-icon">
    <img
      [src]="
        isNotificationOpen
          ? '/assets/icons/notification-icons/notification_active.svg'
          : '/assets/icons/notification-icons/notification.svg'
      "
      alt="Notifications Icon"
    />
  </div>
  <div *ngIf="haveNewNotification" class="ellipse"></div>
</div>

<div class="notification-box" *ngIf="isNotificationOpen">
  <div class="notification-box-content">
    <div class="notification-box-header">
      <div class="notification-text">Notifications</div>
      <mat-slide-toggle
        [(ngModel)]="showUnreadNotification"
        (change)="onToggleChange()"
      >
        Show unread
      </mat-slide-toggle>
    </div>
    <mat-tab-group [(selectedIndex)]="selectedTabIndex">
      <mat-tab>
        <ng-template mat-tab-label>
          Alerts
          <span
            *ngIf="alertCount > 0"
            [ngClass]="[
              selectedTabIndex === 0
                ? 'notification-count-selected'
                : 'notification-count-notselected'
            ]"
            >{{ alertCount }}</span
          >
        </ng-template>
        <app-notification-tab [notificationsData]="alertNotifications">
        </app-notification-tab>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          General
          <span
            *ngIf="generalCount > 0"
            [ngClass]="[
              selectedTabIndex === 1
                ? 'notification-count-selected'
                : 'notification-count-notselected'
            ]"
            >{{ generalCount }}</span
          >
        </ng-template>
        <app-notification-tab [notificationsData]="generalNotifications">
        </app-notification-tab>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
