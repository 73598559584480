import { UserRoles } from "src/app/shared/accessControl/roleInterface";

export const noteSubTypeMap = new Map<string, string[]>([
  ["Select Note Type", [null]],
  [
    "Admission",
    [
      "MICU Template",
      "MICU 2 Template",
      "MICU with meds",
      "HDU Medical",
      "HDU Post-Op",
      "HDU Cardiac",
      "COVID-19",
    ],
  ],
  ["Nutrition", ["MICU Template"]],
  ["Event", ["MICU Template"]],
  [
    "Nursing",
    ["MICU Template", "Nursing Assessment Note", "Nursing Short Note"],
  ],
  ["Pharmacology", ["MICU Template"]],
  [
    "Progress",
    [
      "MICU Template",
      "MICU 2 Template",
      "MICU with meds",
      "HDU Medical",
      "HDU Post-Op",
      "HDU Cardiac",
      "COVID-19",
    ],
  ],
  ["Procedure", ["MICU Template"]],
  ["ICU to ward transfer", ["MICU Template"]],
  ["Consult note", ["MICU Template"]],
  ["Respiratory therapy note", ["MICU Template"]],
  ["Physiotherapy note", ["MICU Template"]],
  ["Discharge", ["MICU Template"]],
  ["Tele critical care review", ["MICU Template"]],
  ["Death summary", ["MICU Template"]],
  ["Shift handover note", ["MICU Template"]],
  ["Intensivist review", ["MICU Template"]],
]);

const docRoles = [
  "Select Note Type",
  "Admission",
  "Event",
  "Progress",
  "Procedure",
  "ICU to ward transfer",
  "Consult note",
  "Discharge",
  "Tele critical care review",
  "Death summary",
  "Shift handover note",
  "Intensivist review",
];
const nurseRoles = [
  "Select Note Type",
  "Admission",
  "Event",
  "Nursing",
  "Progress",
  "Shift handover note",
];
const nutritionistRoles = ["Select Note Type", "Nutrition", "Event"];
const pharmacologistRoles = [
  "Select Note Type",
  "Admission",
  "Event",
  "Pharmacology",
  "Progress",
  "ICU to ward transfer",
  "Discharge",
];
const therapistRoles = [
  "Select Note Type",
  "Event",
  "Physiotherapy note",
  "Respiratory therapy note",
];

export const noteTypeList = new Map<string, string[]>([
  [UserRoles.PHYSICIAN, [...docRoles]],
  [UserRoles.SPECIALIST, [...docRoles]],
  [UserRoles.CCA, [...docRoles]],
  [UserRoles.CCAPHYSICIAN, [...docRoles]],
  [UserRoles.PA, [...docRoles]],
  [UserRoles.NURSE, [...nurseRoles]],
  [UserRoles.CCN, [...nurseRoles, "Intensivist review", "Discharge"]],
  [UserRoles.DIETITIAN, [...nutritionistRoles]],
  [UserRoles.PHARMACOLOGIST, [...pharmacologistRoles]],
  [UserRoles.PT, [...therapistRoles]],
  [UserRoles.RT, [...therapistRoles]],
  [UserRoles.OT, ["Select Note Type", "Event"]],
]);

export const updateNoteSubTypeMapNeonatal = (
  defaultSubtype: Map<string, string[]>
) =>
  defaultSubtype
    .set("Admission", [
      "NICU Template",
      "MICU Template",
      "MICU 2 Template",
      "MICU with meds",
      "HDU Medical",
      "HDU Post-Op",
      "HDU Cardiac",
      "COVID-19",
    ])
    .set("Progress", [
      "NICU progress note L1",
      "NICU progress note L2",
      "NICU progress note L3",
      "MICU Template",
      "MICU 2 Template",
      "MICU with meds",
      "HDU Medical",
      "HDU Post-Op",
      "HDU Cardiac",
      "COVID-19",
    ])
    .set("Nursing", [
      "NICU Template",
      "MICU Template",
      "Nursing Assessment Note",
    ])
    .set("Discharge", ["NICU Template", "MICU Template"]);
