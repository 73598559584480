<div class="netra-notification-model">
  <div class="table-heading">Netra attempts</div>
  <div class="datatable">
    <table mat-table [dataSource]="notificationData">
      <!-- CPMRN Column -->
      <ng-container matColumnDef="CPMRN">
        <th mat-header-cell *matHeaderCellDef>CPMRN</th>
        <td mat-cell *matCellDef="let element">
          {{ element.cpmrn }}
          <span class="ml-1 cpmrn-new-tab-icon">
            <a
              (click)="$event.stopPropagation()"
              href="/patient/{{ element.cpmrn }}/{{
                element.encounters
              }}/entry/vitals"
              target="_blank"
              title="New Tab"
            >
              <img src="assets/icons/notification-icons/new_tab.svg" />
            </a>
          </span>
        </td>
      </ng-container>
      <!-- Name Column -->
      <ng-container matColumnDef="Name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>
      <!-- Hospital Column -->
      <ng-container matColumnDef="Hospital">
        <th mat-header-cell *matHeaderCellDef>Hospital</th>
        <td mat-cell *matCellDef="let element">{{ element.hospital }}</td>
      </ng-container>
      <!-- Unit Column -->
      <ng-container matColumnDef="Unit">
        <th mat-header-cell *matHeaderCellDef>Unit</th>
        <td mat-cell *matCellDef="let element">{{ element.unit }}</td>
      </ng-container>
      <!-- Bed Number Column -->
      <ng-container matColumnDef="Bed">
        <th mat-header-cell *matHeaderCellDef>Bed no</th>
        <td mat-cell *matCellDef="let element">{{ element.bed_number }}</td>
      </ng-container>
      <!-- Time Column -->
      <ng-container matColumnDef="Time">
        <th mat-header-cell *matHeaderCellDef>Time</th>
        <td mat-cell *matCellDef="let element">
          {{ element.timestamp | date: "HH:mm" }}
        </td>
      </ng-container>
      <!-- Info Column -->
      <ng-container matColumnDef="Info">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <img
            src="assets/icons/notification-icons/info.svg"
            [cpPopover]="popoverItem"
            position="bottom-center"
          />
          <ng-template #popoverItem>
            <div class="last-missed-header">Last missed record at :</div>
            <!-- show only last 3 missed entry -->
            <ng-container
              *ngFor="let timestamp of element.last_missed.slice(-3)"
            >
              <div class="last-missed-body">{{ timestamp }}</div>
            </ng-container>
          </ng-template>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
  <div class="close-button">
    <button mat-flat-button class="button-tertiary" (click)="closeTab()">
      Close
    </button>
  </div>
</div>
