export const environment = {
  production: true,
  apiUrl: "https://micro.cloudphysicianworld.org/api/",
  devResUrl: "https://micro.cloudphysicianworld.org/api/",
  vitalsUrl: "https://micro.cloudphysicianworld.org/api/",
  ptzApiUrl: "https://micro.cloudphysicianworld.org/api/",
  socketUrl: "https://micro.cloudphysicianworld.org/",
  aiSocketUrl: "https://micro.cloudphysicianworld.org/api_ai/",
  billingUrl: "https://micro.cloudphysicianworld.org/api/",
  recaptchaKey: "6Lf92cQUAAAAACNlLQM1xofP07v9GlQJxHHa1WGo",
  passwordSecretKey: "CloudPhysician",
  wikiDomain: "help.cloudphysicianworld.com",
  socketPath: "/api/CP-EMR",
  s3region: "ap-south-1",
  s3bucket: "emr-public",
  logApiKey: "27b0c78a7f93945eabfb901f0009f5a6FFFFNRAL",
};
