import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { MatTableModule } from "@angular/material/table";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTabsModule } from "@angular/material/tabs";
import { IrisComponentsModule } from "../iris-components/iris-components.module";
import { NotificationsComponent } from "./notifications/notifications.component";
import { NotificationTabComponent } from "./notification-tab/notification-tab.component";
import { NotificationElementComponent } from "./notification-element/notification-element.component";
import { NetraNotificationComponent } from "./netra-notification/netra-notification.component";
import { SocketService } from "./services/socket.service";

@NgModule({
  declarations: [
    NotificationsComponent,
    NotificationTabComponent,
    NotificationElementComponent,
    NetraNotificationComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatTableModule,
    MatSlideToggleModule,
    MatTabsModule,
    IrisComponentsModule,
  ],
  providers: [SocketService],
  exports: [NotificationsComponent],
})
export class NotificationModule {}
