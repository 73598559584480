<div
  class="notification-bar"
  (click)="openNotification()"
  [ngClass]="{ 'notification-unread': isUnread }"
>
  <ng-container [ngSwitch]="notificationType">
    <!-- Netra missed notification  -->
    <ng-container *ngSwitchCase="notifications.netraMissedVital">
      <img src="assets/icons/notification-icons/netra_notification.svg" />
      <div class="notification-text">
        <div class="notification-title">
          <div class="header">Netra attempt unsuccessful</div>
          <div class="timestamp">{{ notificationTimestamp }}</div>
        </div>
        <div class="message">
          {{
            "Couldn't pick vitals for " +
              notificationContent.notification_data.length +
              " patient" +
              (notificationContent.notification_data.length > 1 ? "s." : ".")
          }}
        </div>
      </div>
    </ng-container>

    <!-- Netra Abnormalvital notification  -->
    <ng-container *ngSwitchCase="notifications.netraAbnormalVital">
      <img src="assets/icons/notification-icons/error_outline.svg" />
      <div class="notification-text">
        <div class="notification-title">
          <div class="header">
            {{ "Abnormal Vitals detected for " + notificationContent.name }}
            <img
              [src]="
                notificationContent.is_resolved
                  ? 'assets/icons/notification-icons/resolved.svg'
                  : 'assets/icons/notification-icons/unresolved.svg'
              "
              style="margin-left: 8px"
              (click)="toggleResolve()"
              [cpPopover]="popoverElement"
            />
            <ng-template #popoverElement>
              <div class="popover-element">
                {{
                  "Mark as " +
                    (notificationContent.is_resolved
                      ? "unresolved"
                      : "resolved")
                }}
              </div>
            </ng-template>
          </div>
          <div class="timestamp">{{ notificationTimestamp }}</div>
        </div>
        <div class="message">
          <p>
            {{ abnormalVitals }} is detected for patient with
            <span class="primary-text">
              CPMRN:{{ notificationContent.cpmrn }}, bed no
              {{ notificationContent.bed_number }}
            </span>
            at
            <span class="primary-text">
              {{ notificationContent.hospital }}/{{ notificationContent.unit }}.
            </span>
          </p>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
