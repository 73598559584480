import { UntypedFormArray, UntypedFormBuilder } from "@angular/forms";
import { Cosign, NoteForm, Notes, NoteType } from "../../model/notes.model";
import { NoteInputComponent } from "../note-input.component";
import * as notesTemplate from "./template";

// default template
const primaryTemplate = notesTemplate.template.get("primaryTemplate");
const diagnosisTemplate = notesTemplate.template.get("diagnosisTemplate");
const secondaryTemplate = notesTemplate.template.get("secondaryTemplateMICU");
const tertiaryTemplate = notesTemplate.template.get("tertiaryTemplate");
const medicationTemplate = notesTemplate.template.get("medicationTemplate");
const reportTemplate = notesTemplate.template.get("reportTemplate");
// progress micu 2 template
const tertiaryMicu2Template = notesTemplate.template.get(
  "tertiaryMICU-2Template"
);

// procedure template
const primaryProcedureTemplate = notesTemplate.template.get(
  "primaryProcedureTemplate"
);
const diagnosisProcedureTemplate = notesTemplate.template.get(
  "diagnosisProcedureTemplate"
);
const secondaryProcedureTemplate = notesTemplate.template.get(
  "secondaryProcedureTemplateMICU"
);
const tertiaryProcedureTemplate = notesTemplate.template.get(
  "tertiaryProcedureTemplateMICU"
);

//ICU to ward transfer
const primaryWardTransferTemplate = notesTemplate.template.get(
  "primaryWardTransferTemplate"
);
const diagnosisWardTransferTemplate = notesTemplate.template.get(
  "diagnosisWardTransferTemplate"
);
const secondaryWardTransferTemplate = notesTemplate.template.get(
  "secondaryWardTransferTemplateMICU"
);
const tertiaryWardTransferTemplate = notesTemplate.template.get(
  "tertiaryWardTransferTemplateMICU"
);

//Consult note
const primaryConsultNoteTemplate = notesTemplate.template.get(
  "primaryConsultNoteTemplate"
);
const secondaryConsultNoteTemplate = notesTemplate.template.get(
  "secondaryConsultNoteTemplate"
);
const tertiaryConsultNoteTemplate = notesTemplate.template.get(
  "tertiaryConsultNoteTemplate"
);

//Intensivist review note
const primaryIntensivistReviewTemplate = notesTemplate.template.get(
  "primaryIntensivistReviewTemplate"
);
const secondaryIntensivistReviewTemplate = notesTemplate.template.get(
  "secondaryIntensivistReviewTemplate"
);

// nursing note template
const secondaryNursingTemplate = notesTemplate.template.get(
  "secondaryNursingTemplate"
);
const tertiaryNursingTemplate = notesTemplate.template.get(
  "tertiaryNursingTemplate"
);

const secondaryNursingShortNoteTemplate = notesTemplate.template.get(
  "secondaryNursingShortNoteTemplate"
);
const tertiaryNursingShortNoteTemplate = notesTemplate.template.get(
  "tertiaryNursingShortNoteTemplate"
);

const diagnosisNursingShortNoteTemplate = notesTemplate.template.get(
  "diagnosisNursingShortNoteTemplate"
);

// admission & progress sub templates
const primaryTemplateHDUPostOp = notesTemplate.template.get(
  "primaryTemplateHDUPostOp"
);
const secondaryTemplateHDUMedical = notesTemplate.template.get(
  "secondaryTemplateHDUMedical"
);
const secondaryTemplateHDUPostOp = notesTemplate.template.get(
  "secondaryTemplateHDUPostOp"
);
const secondaryTemplateHDUCardiac = notesTemplate.template.get(
  "secondaryTemplateHDUCardiac"
);
const secondaryTemplateCOVID19 = notesTemplate.template.get(
  "secondaryTemplateCOVID19"
);

// nursing template
const nursingAssessmentTemplate = notesTemplate.template.get(
  "nursingAssessmentTemplate"
);

//Tele critical care review

const primaryTeleCriticalCareReviewTemplate = notesTemplate.template.get(
  "primaryTeleCriticalCareReviewTemplate"
);
const diagnosisTeleCriticalCareReviewTemplate = notesTemplate.template.get(
  "diagnosisTeleCriticalCareReviewTemplate"
);
const tertiaryTeleCriticalCareReviewTemplate = notesTemplate.template.get(
  "tertiaryTeleCriticalCareReviewTemplateMICU"
);

//Death summary note

const primaryDeathTemplate = notesTemplate.template.get("primaryDeathTemplate");
const diagnosisDeathTemplate = notesTemplate.template.get(
  "diagnosisDeathTemplate"
);
const secondaryDeathTemplate = notesTemplate.template.get(
  "secondaryDeathTemplate"
);
const tertiaryDeathTemplate = notesTemplate.template.get(
  "tertiaryDeathTemplate"
);
const medicationDeathTemplate = notesTemplate.template.get(
  "medicationDeathTemplate"
);
const reportDeathTemplate = notesTemplate.template.get("reportDeathTemplate");

//Nutrition note

const primaryNutritionistTemplate = notesTemplate.template.get(
  "primaryNutritionistTemplate"
);

const secondaryNutritionistTemplate = notesTemplate.template.get(
  "secondaryNutritionistTemplate"
);

// NICU Progress Templates

const primaryTemplateProgressNICUL1 = notesTemplate.template.get(
  "primaryTemplateProgressNICUL1"
);
const diagnosisTemplateProgressNICUL1 = notesTemplate.template.get(
  "diagnosisTemplateProgressNICUL1"
);
const secondaryTemplateProgressNICUL1 = notesTemplate.template.get(
  "secondaryTemplateProgressNICUL1"
);
const tertiaryTemplateProgressNICUL1 = notesTemplate.template.get(
  "tertiaryTemplateProgressNICUL1"
);

// NICU Progress Templates

const primaryTemplateProgressNICUL2 = notesTemplate.template.get(
  "primaryTemplateProgressNICUL2"
);
const diagnosisTemplateProgressNICUL2 = notesTemplate.template.get(
  "diagnosisTemplateProgressNICUL2"
);
const secondaryTemplateProgressNICUL2 = notesTemplate.template.get(
  "secondaryTemplateProgressNICUL2"
);
const tertiaryTemplateProgressNICUL2 = notesTemplate.template.get(
  "tertiaryTemplateProgressNICUL2"
);

// NICU Progress Templates

const primaryTemplateProgressNICUL3 = notesTemplate.template.get(
  "primaryTemplateProgressNICUL3"
);
const diagnosisTemplateProgressNICUL3 = notesTemplate.template.get(
  "diagnosisTemplateProgressNICUL3"
);
const secondaryTemplateProgressNICUL3 = notesTemplate.template.get(
  "secondaryTemplateProgressNICUL3"
);
const tertiaryTemplateProgressNICUL3 = notesTemplate.template.get(
  "tertiaryTemplateProgressNICUL3"
);

// NICU Nursing Templates

const primaryNursingNoteTemplateNICU = notesTemplate.template.get(
  "primaryNursingNoteTemplateNICU"
);
const diagnosisNursingNoteTemplateNICU = notesTemplate.template.get(
  "diagnosisNursingNoteTemplateNICU"
);
const secondaryNursingNoteTemplateNICU = notesTemplate.template.get(
  "secondaryNursingNoteTemplateNICU"
);
const tertiaryNursingNoteTemplateNICU = notesTemplate.template.get(
  "tertiaryNursingNoteTemplateNICU"
);
const medicationNursingNoteTemplateNICU = notesTemplate.template.get(
  "medicationNursingNoteTemplateNICU"
);
const reportNursingNoteTemplateNICU = notesTemplate.template.get(
  "reportNursingNoteTemplateNICU"
);

// NICU Admission

const primaryTemplateNICU = notesTemplate.template.get("primaryTemplateNICU");

const diagnosisTemplateNICU = notesTemplate.template.get(
  "diagnosisTemplateNICU"
);
const secondaryTemplateNICU = notesTemplate.template.get(
  "secondaryTemplateNICU"
);
const tertiaryTemplateNICU = notesTemplate.template.get("tertiaryTemplateNICU");
const medicationTemplateNICU = notesTemplate.template.get(
  "medicationTemplateNICU"
);
const reportTemplateNICU = notesTemplate.template.get("reportTemplateNICU");

// NICU Discharge note

//Death summary note

const primaryNICUDischargeTemplate = notesTemplate.template.get(
  "primaryNICUDischargeTemplate"
);
const diagnosisNICUDischargeTemplate = notesTemplate.template.get(
  "diagnosisNICUDischargeTemplate"
);
const secondaryNICUDischargeTemplate = notesTemplate.template.get(
  "secondaryNICUDischargeTemplate"
);
const tertiaryNICUDischargeTemplate = notesTemplate.template.get(
  "tertiaryNICUDischargeTemplate"
);

export function loadTemplate(noteType, noteSubType) {
  switch (noteType) {
    case "Admission":
    case "Progress": {
      switch (noteSubType) {
        case "NICU Template": {
          if (noteType == "Admission") {
            return {
              summaryTemplate: primaryTemplateNICU,
              diagnosisTemplate: diagnosisTemplateNICU,
              secondaryTemplate: secondaryTemplateNICU,
              medicationTemplate: tertiaryTemplateNICU,
              reportTemplate: medicationTemplateNICU,
              tertiaryTemplate: reportTemplateNICU,
            };
          }
        }
        case "NICU progress note L1": {
          return {
            summaryTemplate: primaryTemplateProgressNICUL1,
            diagnosisTemplate: diagnosisTemplateProgressNICUL1,
            secondaryTemplate: secondaryTemplateProgressNICUL1,
            tertiaryTemplate: tertiaryTemplateProgressNICUL1,
          };
        }
        case "NICU progress note L2": {
          return {
            summaryTemplate: primaryTemplateProgressNICUL2,
            diagnosisTemplate: diagnosisTemplateProgressNICUL2,
            secondaryTemplate: secondaryTemplateProgressNICUL2,
            tertiaryTemplate: tertiaryTemplateProgressNICUL2,
          };
        }
        case "NICU progress note L3": {
          return {
            summaryTemplate: primaryTemplateProgressNICUL3,
            diagnosisTemplate: diagnosisTemplateProgressNICUL3,
            secondaryTemplate: secondaryTemplateProgressNICUL3,
            tertiaryTemplate: tertiaryTemplateProgressNICUL3,
          };
        }
        case "MICU Template": {
          return {
            summaryTemplate: primaryTemplate,
            diagnosisTemplate: diagnosisTemplate,
            secondaryTemplate: secondaryTemplate,
            medicationTemplate: null,
            reportTemplate: null,
            tertiaryTemplate: tertiaryTemplate,
          };
        }
        case "MICU 2 Template": {
          return {
            summaryTemplate: primaryTemplate,
            diagnosisTemplate: diagnosisTemplate,
            secondaryTemplate: secondaryTemplate,
            medicationTemplate: null,
            reportTemplate: null,
            tertiaryTemplate: tertiaryMicu2Template,
          };
        }
        case "HDU Medical": {
          return {
            summaryTemplate: primaryTemplate,
            diagnosisTemplate: diagnosisTemplate,
            secondaryTemplate: secondaryTemplateHDUMedical,
            medicationTemplate: null,
            reportTemplate: null,
            tertiaryTemplate: tertiaryTemplate,
          };
        }
        case "HDU Post-Op": {
          return {
            summaryTemplate: primaryTemplateHDUPostOp,
            diagnosisTemplate: diagnosisTemplate,
            secondaryTemplate: secondaryTemplateHDUPostOp,
            medicationTemplate: null,
            reportTemplate: null,
            tertiaryTemplate: tertiaryTemplate,
          };
        }
        case "HDU Cardiac": {
          return {
            summaryTemplate: primaryTemplate,
            diagnosisTemplate: diagnosisTemplate,
            secondaryTemplate: secondaryTemplateHDUCardiac,
            medicationTemplate: null,
            reportTemplate: null,
            tertiaryTemplate: tertiaryTemplate,
          };
        }
        case "COVID-19": {
          return {
            summaryTemplate: primaryTemplate,
            diagnosisTemplate: diagnosisTemplate,
            secondaryTemplate: secondaryTemplateCOVID19,
            medicationTemplate: null,
            reportTemplate: null,
            tertiaryTemplate: tertiaryTemplate,
          };
        }
        case "MICU with meds": {
          return {
            summaryTemplate: primaryTemplate,
            diagnosisTemplate: diagnosisTemplate,
            secondaryTemplate: secondaryTemplate,
            medicationTemplate: medicationTemplate,
            reportTemplate: reportTemplate,
            tertiaryTemplate: tertiaryTemplate,
          };
        }
      }
    }

    case "Procedure": {
      return {
        summaryTemplate: primaryProcedureTemplate,
        diagnosisTemplate: diagnosisProcedureTemplate,
        secondaryTemplate: secondaryProcedureTemplate,
        medicationTemplate: null,
        reportTemplate: null,
        tertiaryTemplate: tertiaryProcedureTemplate,
      };
    }
    case "ICU to ward transfer": {
      return {
        summaryTemplate: primaryWardTransferTemplate,
        diagnosisTemplate: diagnosisWardTransferTemplate,
        secondaryTemplate: secondaryWardTransferTemplate,
        medicationTemplate: null,
        reportTemplate: null,
        tertiaryTemplate: tertiaryWardTransferTemplate,
      };
    }
    case "Consult note": {
      return {
        summaryTemplate: primaryConsultNoteTemplate,
        diagnosisTemplate: null,
        secondaryTemplate: secondaryConsultNoteTemplate,
        medicationTemplate: null,
        reportTemplate: null,
        tertiaryTemplate: tertiaryConsultNoteTemplate,
      };
    }
    case "Nursing": {
      switch (noteSubType) {
        case "MICU Template": {
          return {
            summaryTemplate: null,
            diagnosisTemplate: diagnosisTemplate,
            secondaryTemplate: secondaryNursingTemplate,
            medicationTemplate: null,
            reportTemplate: null,
            tertiaryTemplate: tertiaryNursingTemplate,
          };
        }
        case "NICU Template": {
          return {
            summaryTemplate: primaryNursingNoteTemplateNICU,
            diagnosisTemplate: diagnosisNursingNoteTemplateNICU,
            secondaryTemplate: secondaryNursingNoteTemplateNICU,
            medicationTemplate: medicationNursingNoteTemplateNICU,
            reportTemplate: reportNursingNoteTemplateNICU,
            tertiaryTemplate: tertiaryNursingNoteTemplateNICU,
          };
        }
        case "Nursing Assessment Note": {
          return {
            summaryTemplate: nursingAssessmentTemplate,
            diagnosisTemplate: null,
            secondaryTemplate: null,
            medicationTemplate: null,
            reportTemplate: null,
            tertiaryTemplate: null,
          };
        }
        case "Nursing Short Note": {
          return {
            summaryTemplate: null,
            diagnosisTemplate: diagnosisNursingShortNoteTemplate,
            secondaryTemplate: secondaryNursingShortNoteTemplate,
            medicationTemplate: null,
            reportTemplate: null,
            tertiaryTemplate: tertiaryNursingShortNoteTemplate,
          };
        }
      }
    }
    case "Tele critical care review": {
      switch (noteSubType) {
        case "MICU Template": {
          return {
            summaryTemplate: primaryTeleCriticalCareReviewTemplate,
            diagnosisTemplate: diagnosisTeleCriticalCareReviewTemplate,
            secondaryTemplate: null,
            medicationTemplate: null,
            reportTemplate: null,
            tertiaryTemplate: tertiaryTeleCriticalCareReviewTemplate,
          };
        }
      }
    }
    case "Death summary": {
      switch (noteSubType) {
        case "MICU Template": {
          return {
            summaryTemplate: primaryDeathTemplate,
            diagnosisTemplate: diagnosisDeathTemplate,
            secondaryTemplate: secondaryDeathTemplate,
            medicationTemplate: medicationDeathTemplate,
            reportTemplate: reportDeathTemplate,
            tertiaryTemplate: tertiaryDeathTemplate,
          };
        }
      }
    }
    case "Nutrition": {
      switch (noteSubType) {
        case "MICU Template": {
          return {
            summaryTemplate: primaryNutritionistTemplate,
            diagnosisTemplate: null,
            secondaryTemplate: secondaryNutritionistTemplate,
            medicationTemplate: null,
            reportTemplate: null,
            tertiaryTemplate: null,
          };
        }
      }
    }
    case "Discharge": {
      switch (noteSubType) {
        case "NICU Template": {
          return {
            summaryTemplate: primaryNICUDischargeTemplate,
            diagnosisTemplate: diagnosisNICUDischargeTemplate,
            secondaryTemplate: secondaryNICUDischargeTemplate,
            medicationTemplate: null,
            reportTemplate: null,
            tertiaryTemplate: tertiaryNICUDischargeTemplate,
          };
        }
        default: {
          return {
            summaryTemplate: primaryTemplate,
            diagnosisTemplate: null,
            secondaryTemplate: null,
            medicationTemplate: null,
            reportTemplate: null,
            tertiaryTemplate: null,
          };
        }
      }
    }
    case "Intensivist review": {
      return {
        summaryTemplate: primaryIntensivistReviewTemplate,
        diagnosisTemplate: diagnosisTemplate,
        secondaryTemplate: secondaryIntensivistReviewTemplate,
        medicationTemplate: null,
        reportTemplate: null,
        tertiaryTemplate: tertiaryTemplate,
      };
    }
    default: {
      return {
        summaryTemplate: primaryTemplate,
        diagnosisTemplate: null,
        secondaryTemplate: null,
        medicationTemplate: null,
        reportTemplate: null,
        tertiaryTemplate: null,
      };
    }
  }
}

export function updateNoteForms(
  note: Notes,
  userDetails: any = null
): NoteForm {
  const noteTypeForm: NoteType = {
    formNoteType: note.content["noteType"],
    formNoteSubType: note.content["noteSubType"],
  };
  const formData: NoteForm = {
    noteTypeForm,
    formNoteActionList: note.content["secondaryText"],
    formNoteSummary: note.content["primaryText"],
    formNoteToDoList: note.content["tertiaryText"],
    formNoteDiagnosis: note.content["diagnosisText"],
    formNoteMedication: note.content["medicationText"],
    formNoteReports: note.content["reportText"],
    noteReferenceId: note["_id"] || note.content["refId"],
    formNoteAddendumArray: note.content["addendum"],
    isDeleteEnabled:
      note["isDeleteEnabled"] != null ? note["isDeleteEnabled"] : null,
    lock: { name: userDetails?.name, uniqueID: userDetails?.email },
    impactCase: note.content.impactCase,
    chargeable: note.content.chargeable,
  };
  return formData;
}

export function makeNoteInputForms(
  noteForm: NoteInputComponent,
  cosign: Cosign,
  formData: NoteForm,
  _fb: UntypedFormBuilder
) {
  noteForm.patientNotesForm.reset();
  noteForm.patientNotesForm.patchValue(formData);
  if (!noteForm.patientNotesForm.get("noteReferenceId").value)
    noteForm.patientNotesForm.get("noteReferenceId").setValue("new");
  if (cosign) {
    noteForm.attestationStatusMsg.setValue(cosign?.physicianMessage);
    noteForm.attestionStatus = cosign?.status;
  }
  noteForm.isAddendum = formData["isAddendum"];
  // addendum Notes
  if (formData.formNoteAddendumArray.length > 0) {
    const addendumControls = noteForm.patientNotesForm.get(
      "formNoteAddendumArray"
    ) as UntypedFormArray;
    addendumControls.clear();
    formData.formNoteAddendumArray.forEach((addendum) => {
      addendumControls.push(
        _fb.group({
          name: addendum["name"],
          note: addendum["note"],
          timestamp: addendum["timestamp"],
        })
      );
    });
  }
}
