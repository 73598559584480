import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class SocketService {
  private socket: WebSocket;
  private userToken: string;
  private maxAttempts = 5;
  private attempt = 1;

  constructor() {
    if (!this.userToken) {
      this.userToken = localStorage.getItem("token");
      if (this.userToken) {
        this.connect(`${environment.aiSocketUrl.replace(/^http/, "ws")}ws`);
      } else {
        console.error("User Token is not available.");
      }
    }
  }

  private connect(url: string): void {
    this.socket = new WebSocket(url);
    // sending user tocken on open to authenticate the connection
    this.socket.addEventListener("open", () => {
      this.socket.send(JSON.stringify(this.userToken));
    });
    this.socket.addEventListener("close", (event) => {
      if (event) {
        console.warn("AI WebSocket disconnected: " + event.reason);
      } else {
        console.warn("AI WebSocket disconnected!");
      }
      // Reconnect if the connection is closed unexpectedly
      if (this.attempt < this.maxAttempts) {
        setTimeout(() => this.connect(url), 1000);
        this.attempt = this.attempt + 1;
      }
    });
  }

  send(message: any): void {
    if (this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(JSON.stringify(message));
    } else {
      console.error("AI WebSocket is not open. Message not sent.");
    }
  }

  onMessage(callback: (message: any) => void): void {
    this.socket.addEventListener("message", (event) => {
      const message = JSON.parse(event.data);
      callback(message);
    });
  }

  close(): void {
    this.socket.close();
  }
}
