import { select, Store } from "@ngrx/store";
import { AppState } from "src/app/reducers";
import { usersCount } from "../../models/user";
import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  AfterViewChecked,
  ChangeDetectorRef,
  QueryList,
  ViewChildren,
} from "@angular/core";
import * as PatientListActions from "src/app/store/actions/patients/patient-list.action";
import * as fromHospitalView from "src/app/store/reducers/hospitals/index";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { AbstractControl, UntypedFormBuilder } from "@angular/forms";
import { MatSort, Sort } from "@angular/material/sort";
import { socket } from "../../config/socket";
import { TemplateRef } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { from } from "rxjs";
import { patientAccess } from "src/app/shared/accessControl/roles";
import { convertToHTTPS } from "src/app/support-functions/linkMods";
import { MatTabGroup } from "@angular/material/tabs";
import {
  getSortedWorkspaceName,
  HIDDEN_WORKSPACE,
  OTHER_WORKSPACE,
} from "../patients-list/patients-list.support";
import { ShiftSortParam } from "src/app/models/patient-list";
import { UserRolesMap } from "src/app/shared/accessControl/roleInterface";
@Component({
  selector: "app-shift",
  templateUrl: "./shift.component.html",
  styleUrls: ["./shift.component.scss"],
})
export class ShiftComponent
  extends UserRolesMap
  implements OnInit, OnChanges, AfterViewChecked
{
  // @ViewChild("paginator") paginator: MatPaginator;
  @ViewChild("assignedPatList", { static: false })
  assignedPatListToggler: MatTabGroup;
  @ViewChildren(MatSort) sorts: QueryList<MatSort>;

  @Input() currentUser;
  sortInAsc = true;
  sortCol = "unit";
  @Input() patientCount: {
    np?: usersCount[];
    rn?: usersCount[];
    docs?: usersCount[];
    ccaPhysician?: usersCount[];
    ccrn?: usersCount[];
  } = {};
  @Input() totalPatientsCount;

  /**
   * @description - holds info about workspace assign/unassign all button
   * prototype: assignAllButtonDisabled['workspace_1'] == true  -> assignAll button will be shown
   *            assignAllButtonDisabled['workspace_1'] == false  -> unassignAll button will be shown
   */
  public assignAllButtonDisabled = {};
  public currUserIsNurse: boolean = false;
  public unitAssignLoaderList$ = this.store.pipe(
    select(fromHospitalView.getUnitAssignLoaderList)
  );

  public assignAllUnitsLoading$ = this.store.pipe(
    select(fromHospitalView.getAssignAllLoading)
  );

  public workspaceAssignLoaderList$ = this.store.pipe(
    select(fromHospitalView.getWorkspaceLoaderList)
  );

  public workSpaceList;
  public assignAllFlag: boolean = true;

  // {
  //   'workspace_name': units[],
  //   'workspace_name2': units[],
  // }
  @Input() set workSpaces(list) {
    this.workSpaceList = list;
    this.assignAllButtonDisabled = Object.keys(this.workSpaceList).reduce(
      (obj, key) => {
        obj[key] = true;
        return obj;
      },
      {}
    );
    this.checkIfAssignAllDisabled();
  }

  @Input() workSpaceInfo;

  shiftAssignTableColumns: string[] = [
    "hospital",
    "physician",
    "nurseNP",
    "nurse",
    "nurseCCRN",
    "phone",
    "speedDial",
    "anydeskId",
    "pacs",
    "lis",
    "meetLink",
    "assign",
  ];

  patientsCountColumns = ["physician", "count"];

  currentPhysicianIndex: number = -1;
  currentNurseIndex: number = -1;
  assignedPatientsCount = 0;
  showFilters: boolean = true;
  roles = patientAccess;

  // dataSource: MatTableDataSource<any>;
  saFilters: AbstractControl;
  isDataSourceSet: boolean;

  workspaceDataSource: any = {};
  dataSource(workSpaceKey: string) {
    return this.workspaceDataSource && this.workspaceDataSource[workSpaceKey];
  }

  workspaceList(workspace) {
    return this.workSpaceList[workspace];
  }

  objectKeys = Object.keys;
  public hiddenWorkspaceName = HIDDEN_WORKSPACE;
  public otherWorkspaceName = OTHER_WORKSPACE;

  constructor(
    private appStore: Store<AppState>,
    private store: Store<any>,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private _cdrf: ChangeDetectorRef
  ) {
    super();
    this.saFilters = this.formBuilder.group({
      hospital: [null],
      physician: [null],
      nurse: [null],
      roles: [null],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setAssignedPatientsCount();
  }

  ngOnInit() {
    this.roles = this.roles.filter((role) => {
      if (
        role !== this.PHYSICIAN &&
        role !== this.CCN &&
        role !== this.NURSE &&
        role !== this.CCA
      )
        return true;
      return false;
    });
    // displaying columns on the basis of roles selected.
    this.saFilters.valueChanges.subscribe((value) => {
      this.roles.forEach((role) => {
        const roleCol = role.toLowerCase().split(" ").join("-"),
          colSize = this.shiftAssignTableColumns.length;

        if (value?.roles?.includes(role)) {
          if (!this.shiftAssignTableColumns.includes(roleCol))
            this.shiftAssignTableColumns.splice(colSize - 7, 0, roleCol);
        } else {
          if (this.shiftAssignTableColumns.includes(roleCol))
            this.shiftAssignTableColumns = this.shiftAssignTableColumns.filter(
              (col) => col !== roleCol
            );
        }
      });
    });
  }

  ngAfterViewChecked() {
    // if (
    //   this.workSpaceList &&
    //   Object.keys(this.workSpaceList).length
    //   //&& !this.isDataSourceSet
    // ) {
    //   console.log('work');
    //   getSortedWorkspaceName(Object.keys(this.workSpaceList)).forEach(
    //     (key, i) => {
    //       this.workspaceDataSource[key] = new MatTableDataSource(
    //         this.workSpaceList[key]
    //       );
    //       this.workspaceDataSource[key].sortingDataAccessor =
    //         this.setSortingDataForMatTable();

    //       if (this.sorts?.length) {
    //         const innerSort = this.sorts?.find((_, index) => index == i);
    //         if (!innerSort) return;

    //         innerSort.disableClear = true;
    //         this.workspaceDataSource[key].sort = innerSort;
    //       }
    //     }
    //   );
    //   this.isDataSourceSet = true;
    //   this._cdrf.detectChanges();
    // }
    this.assignedPatListToggler.realignInkBar();
  }

  // setSortingDataForMatTable() {
  //   return (unit, property) => {
  //     switch (property) {
  //       case "hospital":
  //         return unit.hospitalInfo?.name?.toLowerCase();
  //       case "physician":
  //         return unit.docAssigned?.name?.toLowerCase();
  //       case "nurseNP":
  //         return unit?.npAssigned?.name?.toLowerCase();
  //       case "nurse":
  //         return unit.rnAssigned?.name?.toLowerCase();
  //       case "nurseCCRN":
  //         return unit.ccrnAssigned?.name?.toLowerCase();
  //       case "dietitian":
  //         return unit.dietitianAssigned?.name?.toLowerCase();
  //       case "specialist":
  //         return unit.specialistAssigned?.name?.toLowerCase();
  //       case "pharmacologist":
  //         return unit.pharmacologistAssigned?.name?.toLowerCase();
  //       case "lab-technician":
  //         return unit.labTechnicianAssigned?.name?.toLowerCase();
  //       case "documentation-specialist":
  //         return unit.documentationSpecialistAssigned?.name?.toLowerCase();
  //       case "physical-therapist":
  //         return unit.physicalTherapistAssigned?.name?.toLowerCase();
  //       case "physician-associate":
  //         return unit.physicianAssociateAssigned?.name?.toLowerCase();
  //       case "occupational-therapist":
  //         return unit.occupationalTherapistAssigned?.name?.toLowerCase();
  //       case "respiratory-therapist":
  //         return unit.respiratoryTherapistAssigned?.name?.toLowerCase();

  //       default:
  //         return unit[property];
  //     }
  //   };
  // }

  checkIfAssignAllDisabled() {
    if (this.workSpaceList && Object.keys(this.workSpaceList).length) {
      const isDoc = this.currentUser?.role === this.PHYSICIAN;
      const isNp = this.currentUser?.role === this.CCA;
      const isCcrn = this.currentUser?.role === this.CCN;
      const isRn = this.currentUser?.role === this.NURSE;
      const isCCAPhy = this.currentUser?.role === this.CCAPHYSICIAN;

      let assignedUnit = "rnAssigned";
      if (isDoc) assignedUnit = "docAssigned";
      else if (isNp) assignedUnit = "npAssigned";
      else if (isCcrn) assignedUnit = "ccrnAssigned";
      else if (isRn) assignedUnit = "rnAssigned";
      else if (isCCAPhy) assignedUnit = "ccaPhysicianAssigned";
      else if (this.currentUser?.role === "Dietitian")
        assignedUnit = "dietitianAssigned";
      else if (this.currentUser?.role === "Specialist")
        assignedUnit = "specialistAssigned";
      else if (this.currentUser?.role === "Lab Technician")
        assignedUnit = "labTechnicianAssigned";
      else if (this.currentUser?.role === "Pharmacologist")
        assignedUnit = "pharmacologistAssigned";
      else if (this.currentUser?.role === "Documentation Specialist")
        assignedUnit = "documentationSpecialistAssigned";
      else if (this.currentUser?.role === "Physical Therapist")
        assignedUnit = "physicalTherapistAssigned";
      else if (this.currentUser?.role === "Physician Associate")
        assignedUnit = "physicianAssociateAssigned";
      else if (this.currentUser?.role === "Occupational Therapist")
        assignedUnit = "occupationalTherapistAssigned";
      else if (this.currentUser?.role === "Respiratory Therapist")
        assignedUnit = "respiratoryTherapistAssigned";
      else if (this.currentUser?.role === "Data Entry Associate")
        assignedUnit = "dataEntryAssociateAssigned";

      this.setAssignButtonText();
      this.setAssignedPatientsCount();

      this.setAssignAll(assignedUnit);
    }
  }

  /**
   *
   * @param assignedUnit - current role obj
   * @description - if find any unit assigned to me make assignAllButtonDisabled['workspace_1'] = false => making it 'unassign all'
   *              - else assignAllButtonDisabled['workspace_2'] = true => making it 'assign all'
   */
  setAssignAll(assignedUnit) {
    for (let [key, units] of Object.entries(this.workSpaceList) as any) {
      this.assignAllButtonDisabled[key] =
        units?.length &&
        !units.some((unit) => {
          let aUnit = unit[assignedUnit];

          return !!unit.active && aUnit?.email === this.currentUser?.email;
        });
    }
  }

  onAssign(unit, isAssign) {
    let data = {
      unit: unit._id,
      currentUser: this.currentUser.name,
      role: this.currentUser.role,
      timestamp: new Date(),
      email: this.currentUser.email,
      isAssign,
    };

    this.appStore.dispatch(PatientListActions.assignShift({ shift: data }));

    // if(this.currentUser && this.currentUser.role === 'Physician')
    //     this.store.dispatch(PatientListActions.assignShiftDoc({shift: data}));
    //     this.store.dispatch(PatientListActions.assignShiftNp({shift: data}));
    // else
    //     this.store.dispatch(PatientListActions.assignShiftNurse({shift: data}));
  }

  assignAllUnits(isAssign) {
    const req = {
      name: this.currentUser.name,
      role: this.currentUser.role,
      email: this.currentUser.email,
      isAssign,
    };
    this.appStore.dispatch(PatientListActions.assignAllShift(req));
  }

  assignAllWorkspace(isAssign, workspaceId) {
    const workspace = {
      workspaceId,
      isAssign,
    };
    this.appStore.dispatch(
      PatientListActions.assignAllWorkspace({ workspace })
    );
  }

  callToPhone(val) {
    let el = document.getElementsByClassName(val)[0];
    let range = document.createRange();
    range.selectNodeContents(el);
    let sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
  }

  openAssignAllConfirmationModal(
    content: TemplateRef<any>,
    workSpaceId,
    workspaceName
  ) {
    const dialogRef = this.dialog.open(content, {
      height: "180px",
      width: "325px",
      autoFocus: false,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == "yes") {
        const setWorksSpaceAll =
          this.assignAllButtonDisabled[this.worksSpaceName];
        workspaceName == this.hiddenWorkspaceName
          ? this.assignAllUnits(this.assignAllFlag)
          : this.assignAllWorkspace(setWorksSpaceAll, workSpaceId);
        // this.assignAllUnits(this.assignAllFlag);
      }
    });
  }

  private _workSpace: string;

  set worksSpaceName(name: string) {
    this._workSpace = name;
  }

  get worksSpaceName() {
    return this._workSpace;
  }

  get contentToDisplay() {
    if (this.worksSpaceName === this.hiddenWorkspaceName)
      return "Assign to all units?";

    return !!this.assignAllButtonDisabled[this.worksSpaceName]
      ? `Are you sure you want to assign all the units of ${this.worksSpaceName}?`
      : `Are you sure you want to unassign all the units of ${this.worksSpaceName}?`;
  }

  get actionToDo() {
    return !!this.assignAllButtonDisabled[this.worksSpaceName]
      ? `Assign`
      : `Unassign`;
  }

  setAssignedPatientsCount() {
    let role = "";
    switch (this.currentUser.role) {
      case this.PHYSICIAN:
        role = "docs";
        break;

      case this.CCA:
        this.currUserIsNurse = true;
        role = "np";
        break;

      case this.CCN:
        this.currUserIsNurse = true;
        role = "ccrn";
        break;

      case this.NURSE:
        this.currUserIsNurse = true;
        role = "rn";
        break;

      case this.DIETITIAN:
        role = "dietitian";
        break;

      case this.SPECIALIST:
        role = "specialist";
        break;

      case this.PHARMACOLOGIST:
        role = "pharmacologist";
        break;

      case this.LT:
        role = "labTechnician";
        break;

      case this.DS:
        role = "documentationSpecialist";
        break;

      case this.PT:
        role = "physicalTherapist";
        break;

      case this.PA:
        role = "physicianAssociate";
        break;

      case this.OT:
        role = "occupationalTherapist";
        break;

      case this.RT:
        role = "respiratoryTherapist";
        break;

      case this.DEA:
        role = "dataEntryAssociate";
        break;

      case this.CCAPHYSICIAN:
        role = "ccaPhysician";
        break;
    }

    this.assignedPatientsCount =
      this.patientCount[role]?.find(
        (user) => user.email === this.currentUser.email
      )?.count || 0;
  }

  setAssignButtonText() {
    for (let units of Object.values(this.workSpaceList) as Array<any>) {
      if (!units || !units.length) continue;

      units.forEach((unit) => {
        switch (this.currentUser.email + this.currentUser.role) {
          case unit?.rnAssigned?.email + this.NURSE:
          case unit?.ccrnAssigned?.email + this.CCN:
          case unit?.npAssigned?.email + this.CCA:
          case unit?.docAssigned?.email + this.PHYSICIAN:
          case unit?.dietitianAssigned?.email + this.DIETITIAN:
          case unit?.specialistAssigned?.email + this.SPECIALIST:
          case unit?.pharmacologistAssigned?.email + this.PHARMACOLOGIST:
          case unit?.labTechnicianAssigned?.email + this.LT:
          case unit?.documentationSpecialistAssigned?.email + this.DS:
          case unit?.physicalTherapistAssigned?.email + this.PT:
          case unit?.physicianAssociateAssigned?.email + this.PA:
          case unit?.occupationalTherapistAssigned?.email + this.OT:
          case unit?.respiratoryTherapistAssigned?.email + this.RT:
          case unit?.dataEntryAssociateAssigned?.email + this.DEA:
          case unit?.ccaPhysicianAssigned?.email + this.CCAPHYSICIAN:
            unit["assignButtonText"] = "Unassign";
            break;

          default:
            unit["assignButtonText"] = "Assign";
            break;
        }
      });
    }
  }

  /**Method to clear all the filters applied */
  clearFilters() {
    this.saFilters.reset();
  }

  hideFilters(hide: boolean) {
    this.showFilters = !hide;
  }

  updateLink(link: string): string {
    return convertToHTTPS(link);
  }

  isGroup(index, item) {
    return item.isWorkspace;
  }

  // old - code
  sortParam: ShiftSortParam;
  onSort(sortEvent: any, workspaceName) {
    this.sortParam = {
      type: sortEvent.active,
      direction: sortEvent.direction,
      workspace: workspaceName,
    };
    // const sortedData = this.workSpaceList[workspaceName].slice().sort(
    //   (a, b) => {
    //     // a = a.hospitalInfo.name.toLowerCase();
    //     // b = b.hospitalInfo.name.toLowerCase();
    //     // if (a > b) {
    //     //   return -1;
    //     // }
    //     // if (b > a) {
    //     //   return 1;
    //     // }
    //     // return 0;
    //     const [s1, s2] = this.getSortValues(sortEvent.active, a, b);
    //     const sortOrder = this.getSortOrder(sortEvent.direction === 'asc' ? true : false);

    //     // console.log(val1, val2);
    //     if (s1 < s2) {
    //       return sortOrder.less;
    //     }
    //     if (s1 > s2) {
    //       return sortOrder.more;
    //     }
    //     return 0;
    //   }
    // );
    // this.workSpaceList[workspaceName] = sortedData.slice();
    // console.log(sortedData);
    // this.workSpaceList[workspaceName] = cloneWorkspaceList;
    // console.log("sorted", cloneWorkspaceList);
  }
}
