/**
 * Notifications Component.
 * Displays all the application notifications.
 * @author Pradip Solanki
 * @example
 * <app-notifications></app-notifications>
 */

import { Component, OnInit, OnDestroy, HostListener } from "@angular/core";
import { SocketService } from "../services/socket.service";
import { NotificationService } from "../services/notification.service";
import { Topic } from "../enums/topic.enum";
import { Subscription } from "rxjs";

@Component({
  selector: "app-notifications",
  templateUrl: "./notifications.component.html",
  styleUrls: ["./notifications.component.scss"],
})
export class NotificationsComponent implements OnInit, OnDestroy {
  // variable to indicate new notification on top of bell icon
  public haveNewNotification: boolean = false;
  // variable for opening notification panel with different tabs and change color of bell icon
  public isNotificationOpen: boolean = false;
  // variable to show unread notifications
  public showUnreadNotification: boolean = true;
  // list of notifications for each tab
  public generalNotifications: any = [];
  public alertNotifications: any = [];
  public alertCount: number = 0;
  public generalCount: number = 0;
  public selectedTabIndex: number = 0;
  private subscription: Subscription;
  private currentUser: any;

  constructor(
    private socketService: SocketService,
    private notificationService: NotificationService
  ) {}

  @HostListener("document:click", ["$event"])
  onClick(event: MouseEvent) {
    // check if the click event target is outside of the div
    if ((event.target as HTMLElement).closest(".notification-center-icon")) {
      if (this.isNotificationOpen) {
        this.isNotificationOpen = false;
      } else {
        this.isNotificationOpen = true;
      }
    } else if (
      !(event.target as HTMLElement).closest(".notification-box") &&
      !(event.target as HTMLElement).closest(".cdk-overlay-container")
    ) {
      this.isNotificationOpen = false;
    }
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.socketService.onMessage((message) => {
      this.handleMessage(message);
    });
    this.subscription = this.notificationService
      .getDialogOpen()
      .subscribe((result) => {
        this.isNotificationOpen = result;
      });
  }

  handleMessage(message: any): void {
    if (message["topic"] === Topic.setNotification) {
      this.showUnreadNotification = message["unread_only"];
      this.setNotifications(message);
    } else if (message["topic"] === Topic.connected) {
      // on connect ask for notifications
      this.socketService.send({
        topic: Topic.getNotification,
        role: this.currentUser["role"],
        email: this.currentUser["email"],
      });
    } else {
      console.error("Recieved message with topic:" + message["topic"]);
    }
  }

  onToggleChange() {
    // fetch notifications again with new filter
    this.socketService.send({
      topic: Topic.setPreference,
      role: this.currentUser["role"],
      email: this.currentUser["email"],
      unread_only: this.showUnreadNotification,
    });
  }

  ngOnDestroy(): void {
    // remove socket on close
    this.socketService.close();
    this.subscription.unsubscribe();
  }

  setNotifications(notifications: any): void {
    this.haveNewNotification = false;
    this.alertNotifications = notifications["alert_notifications"];
    this.generalNotifications = notifications["general_notifications"];
    this.alertCount = notifications["new_alert_count"];
    this.generalCount = notifications["new_general_count"];
    if (this.alertCount + this.generalCount > 0) {
      this.haveNewNotification = true;
    }
  }
}
