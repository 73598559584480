import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-netra-notification",
  templateUrl: "./netra-notification.component.html",
  styleUrls: ["./netra-notification.component.scss"],
})
export class NetraNotificationComponent implements OnInit {
  displayedColumns: string[] = [
    "CPMRN",
    "Name",
    "Hospital",
    "Unit",
    "Bed",
    "Time",
    "Info",
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public notificationData: any,
    private dialogRef: MatDialogRef<NetraNotificationComponent>
  ) {}

  ngOnInit(): void {
    // console.log(this.notificationData);
  }

  transformTimestamp(timestamp: string): string {
    const date = new Date(timestamp);
    return date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
    });
  }

  closeTab(): void {
    this.dialogRef.close();
  }
}
