import { Injectable } from "@angular/core";
import { Subject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  private hasNotificationOpen = new Subject<boolean>();

  setDialogOpen(message: boolean): void {
    this.hasNotificationOpen.next(message);
  }

  getDialogOpen(): Observable<boolean> {
    return this.hasNotificationOpen.asObservable();
  }
}
