/* ==============================
  NAME: UserRoles
  PURPOSE: Sets the various user roles					
  USED BY: Radar web components
  AUTHOR: RAKESH .R
  DATE: 13/January/2023
  ================================= */

export enum UserRoles {
  DIETITIAN = "Dietitian",
  CCN = "Critical Care Nurse",
  NURSE = "Nurse",
  CCA = "Critical Care Associate",
  PHARMACOLOGIST = "Pharmacologist",
  PHYSICIAN = "Physician",
  SPECIALIST = "Specialist",
  CCAPHYSICIAN = "CCA Physician",
  LT = "Lab Technician",
  DS = "Documentation Specialist",
  PT = "Physical Therapist",
  OT = "Occupational Therapist",
  RT = "Respiratory Therapist",
  DEA = "Data Entry Associate",
  PA = "Physician Associate",
  SA = "System Administrator",
}

/* ==============================
  NAME: UserRoles
  PURPOSE: Sets the various user roles					
  USED BY: Radar web components
  AUTHOR: RAKESH .R
  DATE: 13/January/2023
  ================================= */
export abstract class UserRolesMap {
  DIETITIAN = UserRoles.DIETITIAN;
  CCN = UserRoles.CCN;
  NURSE = UserRoles.NURSE;
  CCA = UserRoles.CCA;
  CCAPHYSICIAN = UserRoles.CCAPHYSICIAN;
  PHARMACOLOGIST = UserRoles.PHARMACOLOGIST;
  PHYSICIAN = UserRoles.PHYSICIAN;
  SPECIALIST = UserRoles.SPECIALIST;
  LT = UserRoles.LT;
  DS = UserRoles.DS;
  PT = UserRoles.PT;
  OT = UserRoles.OT;
  RT = UserRoles.RT;
  DEA = UserRoles.DEA;
  PA = UserRoles.PA;
  SA = UserRoles.SA;
}
