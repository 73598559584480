export const version = {
  no: "V5.16.0",
  message: `
  - vitals abusive call fix
  - NICU dose calculator : Medication order workflow change
  - Automating Neonatal Discharge Summary Generation with GPT
  - Neonatal - Radar should be able to admit a new born within minutes of the birth
  - Implement WhatsApp login for International Radar Users
  - Neonatal Discharge note - Discharge Exam Section has to be removed 
  `,
};
