import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import * as moment from "moment";
import { NetraNotificationComponent } from "../netra-notification/netra-notification.component";
import { SocketService } from "../services/socket.service";
import { NotificationType } from "../enums/notificationtype.enum";
import { Topic } from "../enums/topic.enum";

@Component({
  selector: "app-notification-element",
  templateUrl: "./notification-element.component.html",
  styleUrls: ["./notification-element.component.scss"],
})
export class NotificationElementComponent implements OnInit, OnDestroy {
  @Input() notificationContent: any;
  @Output() notificationClicked = new EventEmitter<void>();

  public notifications = NotificationType; // expose the enum to the template
  public notificationType: string;
  public notificationTimestamp: string;
  public isUnread: boolean;
  public abnormalVitals: string;
  private updateNotificationTimer: any;

  constructor(public dialog: MatDialog, private socketService: SocketService) {}

  ngOnInit(): void {
    this.isUnread = !this.notificationContent["is_read"];
    this.notificationType = this.notificationContent["type"];
    this.updateNotificationTimestamp();
    if (this.notificationContent["abnormal_vitals"]) {
      this.abnormalVitals = "Abnormal value in ";
      const vitalsLength = Object.keys(
        this.notificationContent["abnormal_vitals"]
      ).length;
      let index = 0;
      Object.keys(this.notificationContent["abnormal_vitals"]).forEach(
        (key) => {
          index = index + 1;
          const separator =
            index > 1 ? (index < vitalsLength ? ", " : " and ") : "";
          this.abnormalVitals = this.abnormalVitals + separator;
          this.abnormalVitals =
            this.abnormalVitals +
            key +
            ":" +
            this.notificationContent["abnormal_vitals"][key];
        }
      );
    }
  }

  ngOnDestroy() {
    clearTimeout(this.updateNotificationTimer);
  }

  updateNotificationTimestamp() {
    this.notificationTimestamp = this.formatDateTime(
      this.notificationContent["timestamp"]
    );
    this.updateNotificationTimer = setTimeout(() => {
      this.updateNotificationTimestamp();
    }, 60000); // 60000 milliseconds = 1 minute
  }

  formatDateTime(timestamp: string): string {
    const now = moment();
    const targetTime = moment(timestamp, "YYYY-MM-DD HH:mm:ss");
    const minutesAgo = now.diff(targetTime, "minutes");
    const hoursAgo = now.diff(targetTime, "hours");
    const daysAgo = now.diff(targetTime, "days");
    if (minutesAgo < 1) {
      return "Just now";
    } else if (minutesAgo < 60) {
      return `${minutesAgo}m ago`;
    } else if (hoursAgo < 24) {
      return `${hoursAgo}h ago`;
    } else {
      return `${daysAgo}day${daysAgo > 1 ? "s" : ""} ago`;
    }
  }

  openNotification(): void {
    this.notificationClicked.emit();
    if (this.notificationType === this.notifications.netraMissedVital) {
      this.dialog.open(NetraNotificationComponent, {
        width: "720px",
        height: "480px",
        data: this.notificationContent["notification_data"],
        panelClass: "notification-dialog-container",
      });
    }
  }

  toggleResolve(): void {
    let is_resolved = true;
    if (this.notificationContent["is_resolved"]) {
      is_resolved = false;
    }
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.socketService.send({
      topic: Topic.markNotificationResolved,
      role: currentUser["role"],
      email: currentUser["email"],
      notification_ids: this.notificationContent["_id"],
      is_resolved: is_resolved,
    });
  }
}
