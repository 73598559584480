<div class="status-chip-container display-flex justify-content-center">
  <button
    class="status-button"
    [ngStyle]="{ width: statusConfig?.buttonWidth }"
    [ngClass]="statusConfig?.colorClass"
  >
    {{ statusConfig?.buttonText }}
  </button>
  <mat-icon
    *ngIf="statusConfig?.isNextIconVisible"
    class="customIconSize cursor-pointer"
    matTooltip="Click here to change status"
    (click)="changeStatus()"
  >
    play_arrow
  </mat-icon>
</div>
