import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { VitalsRoutingModule } from "./vitals-routing.module";
import { VitalViewComponent } from "./vitals/vital-view/vital-view.component";
import { VitalInputComponent } from "./vitals/vital-input/vital-input.component";
import { VitalsComponent } from "./vitals/vitals.component";
import { VitalDayPipe } from "./pipes/vital-day.pipe";
import { UniqueDayPipe } from "./pipes/unique-day.pipe";
import { FallRiskComponent } from "./vitals/vital-input/fall-risk/fall-risk.component";
import { BradenComponent } from "./vitals/vital-input/braden/braden.component";
// import { ComaComponent } from "./vitals/vital-input/coma/coma.component";
import { PainComponent } from "./vitals/vital-input/pain/pain.component";
import { RassComponent } from "./vitals/vital-input/rass/rass.component";
import { VitalsFormComponent } from "./vitals/vital-input/vitals-form/vitals-form.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { VitalModule } from "../vital/vital.module";
import { IrisComponentsModule } from "../iris-components/iris-components.module";
import { SharedModule } from "../shared/shared.module";
import { VentsComponent } from "./vitals/vital-input/vents/vents.component";
import { AssessmentComponent } from "./assessment/assessment.component";
import { DaySelectorComponent } from "./vitals/day-selector/day-selector.component";
import { AssessmentViewComponent } from "./assessment/assessment-view/assessment-view.component";
import { RemoveBlankEntriesPipeModule } from "../shared-modules/pipes/remove-blank-entries/remove-blank-entries-pipe.module";
import { TrendsModule } from "../shared-modules/features/trends/trends.module";
import { CribComponent } from "./vitals/vital-input/crib/crib.component";
import { ApgarScoreComponent } from "./vitals/vital-input/apgar-score/apgar-score.component";
import { AssessmentNcpTabComponent } from "../assessment/assessment-ncp-tab/assessment-ncp-tab.component";
import { NcpViewComponent } from "../assessment/nursing-care-plan/components/ncp-view/ncp-view.component";
import { NcpInputComponent } from "../assessment/nursing-care-plan/components/ncp-input/ncp-input.component";
import { NcpInputFormComponent } from "../assessment/nursing-care-plan/components/ncp-input-form/ncp-input-form.component";
import { NcpModalComponent } from "../assessment/nursing-care-plan/components/ncp-modal/ncp-modal.component";

import { SearchableDropdownModule } from "@shared-modules/components/searchable-dropdown/searchable-dropdown.module";
import { DropdownTransformModule } from "@shared-modules/pipes/dropdown-transform/dropdown-transform.module";
import { SnappScoreComponent } from "./vitals/vital-input/snapp-score/components/snapp-score.component";
import { InfectionBundleComponent } from "./assessment/infection-bundle/components/infection-bundle-view/infection-bundle.component";
import { StoreModule } from "@ngrx/store";
import { reducer } from "./assessment/infection-bundle/store/reducers";
import { infectionBundleEffects } from "./assessment/infection-bundle/store/effects";
import { EffectsModule } from "@ngrx/effects";
import { FindObjectInArrayWithKeyModule } from "@shared-modules/pipes/find-object-in-array-with-key/find-object-in-array-with-key.module";

import { MultiselectChiplistComponent } from "../multiselect-chiplist/multiselect-chiplist/multiselect-chiplist.component";
import * as fromNcp from "../assessment/nursing-care-plan/store/reducers";
import { NcpCreateEffects } from "../assessment/nursing-care-plan/store/effects/ncp.effect";
import { ToggleHeaderModule } from "../iris-components/toggle-header/toggle-header.module";
import { InfTableComponent } from "./assessment/infection-bundle/components/inf-table/inf-table.component";
import { TimezonePipeModule } from "@shared-modules/pipes/timezone-pipe/timezone-pipe.module";
import { TimezoneLabelModule } from "@shared-modules/components/timezone-label/timezone-label.module";
import { ThompsonScoreComponent } from "./vitals/vital-input/thompson-score/thompson-score/thompson-score.component";
import { TrendsComponent } from "@shared-modules/features/trends/components/old-trends/trends.component";
import { KeyValueWithoutSortingModule } from "../shared-modules/pipes/keyValue-withoutsorting/keyvalue-withoutsorting.module";
import { CollapseRowDirective } from "./directives/collapse-row.directive";

@NgModule({
  declarations: [
    VitalViewComponent,
    VitalInputComponent,
    VitalsComponent,
    VitalDayPipe,
    UniqueDayPipe,
    FallRiskComponent,
    BradenComponent,
    CollapseRowDirective,
    // ComaComponent,
    PainComponent,
    RassComponent,
    VitalsFormComponent,
    VentsComponent,
    AssessmentComponent,
    DaySelectorComponent,
    AssessmentViewComponent,
    CribComponent,
    ApgarScoreComponent,
    AssessmentNcpTabComponent,
    NcpViewComponent,
    NcpInputComponent,
    NcpInputFormComponent,
    NcpModalComponent,
    SnappScoreComponent,
    InfectionBundleComponent,
    AssessmentNcpTabComponent,
    NcpViewComponent,
    NcpInputComponent,
    NcpInputFormComponent,
    NcpModalComponent,
    MultiselectChiplistComponent,
    InfTableComponent,
    ThompsonScoreComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    IrisComponentsModule,
    VitalsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    VitalModule,
    RemoveBlankEntriesPipeModule,
    TrendsModule,
    FindObjectInArrayWithKeyModule,
    ToggleHeaderModule,

    StoreModule.forFeature("infection-bundle", reducer),
    EffectsModule.forFeature(infectionBundleEffects),
    TimezonePipeModule,
    TimezoneLabelModule,
    SearchableDropdownModule,
    DropdownTransformModule,
    KeyValueWithoutSortingModule,
  ],
  exports: [
    VitalViewComponent,
    VitalInputComponent,
    VitalsComponent,
    VitalDayPipe,
    UniqueDayPipe,
    FallRiskComponent,
    BradenComponent,
    PainComponent,
    RassComponent,
    VitalsFormComponent,
    VentsComponent,
    AssessmentComponent,
    DaySelectorComponent,
    AssessmentViewComponent,
    TrendsComponent,
  ],
  providers: [VitalDayPipe],
})
export class VitalsModule {}
