import { FormArray, UntypedFormControl } from "@angular/forms";
import { type } from "os";
import { Observable } from "rxjs";

export class Labs {
  file: File[];
  ordersArray: string[];
}

export enum Category {
  LAB = "labs",
  SCANS = "scans",
  DOCUMENTS = "documents",
}

export interface File {
  id: string;
  name: string;
  size: number;
  fileUrl: string;
  type: string;
}

export interface Task {
  id: number;
  type: string | number;
  input: any;
  createdBy: string;
  key: string;
  status: string;
  collType: string;
  priority: number;
  output: any;
}

export interface TaskBE {
  clientId: number;
  type: string | number;
  input: any;
  createdBy: string;
  key: string;
  status: string;
  collType: string;
  priority: number;
  output: any;
}

export class LabRequest {
  name: string;
  type?: string;
  subType?: string;
  requestedBy: string;
  loincID?: UntypedFormControl;
  status: string;
  createdOn: Date;
  updatedOn: Date;
  isGenericLoincId$: Observable<boolean> | null;
}

type rangeType = { min: string | number; max: string | number } | null;
export class Attributes {
  field: String;
  alias: [];
  patientType?: string | null;
  type: String;
  unit: String;
  errorRange: rangeType;
  validationRange: rangeType;
  neonatalValidationRange?: rangeType;
  neonatalErrorRange?: rangeType;
  pediatricErrorRange?: rangeType;
  pediatricValidationRange?: rangeType;
}

export class LabData {
  fields: Attributes[];
  alias: [];
  name: string;
  loinc: string;
  patientType: string[] | null;
  type: "Panel";
  _id: string;
  category?: string;
  classifications: { patientType: string; classifications: string[] }[];
}
export interface SpotlightAttributes extends Omit<DocumentAttributes, "value"> {
  records: value[];
}

export interface GroupedDocument {
  headInfo: LabHeadData[];
  attributes: Attributes[];
  labIds: string[];
}
export interface Documents extends DocumentViews {
  alias: string[];
  name: string;
  classifications: string[];
  isInactive: boolean;
  sbarReferenceId: string;
  tags: string[];
}
export interface labValues {
  headInfo: LabHeadData[];
  attributes: { [key: string]: DocumentAttributes }[];
  labIds: string[];
}

export class LabHeadData {
  isAdmission: boolean;
  reportedAt?: Date;
  time: string;
  key: string;
  secondaryKeys: string[];
}

export class DocumentAttributes {
  errorRange: rangeType = { max: null, min: null };
  validationRange: rangeType = { max: null, min: null };
  value: string;
}

type value = { value: string; time: string };
export interface SpotlightAttributes extends Omit<DocumentAttributes, "value"> {
  records: value[];
}

export interface GroupedDocument {
  headInfo: LabHeadData[];
  attributes: Attributes[];
  labIds: string[];
}

export interface SpotlightDocuments {
  [key: string]: SpotlightAttributes;
}

export type DocumentViewsMap = ReadonlyMap<string, DocumentViews[]>;
export type labViewsMap = ReadonlyMap<string, labelType[]>;

export class DocumentViews {
  attributes: { [key: string]: DocumentAttributes };
  category?: string;
  createdAt?: Date;
  label: string;
  reportedAt: Date;
  admission_lab: boolean;
  _id: string;
  key: string;
  secondaryKeys: string[];
}
export interface Documents extends DocumentViews {
  alias: string[];
  name: string;
  classifications: string[];
  isInactive: boolean;
  sbarReferenceId: string;
  tags: string[];
}

export type labelType = { name: string; checked: boolean } | null;
export type patientType = "adult" | "pediatric" | "neonatal";

export type currentPatient = {
  CPMRN: string;
  encounters: number;
  pacsLink: string;
};

export interface labValues {
  headInfo: LabHeadData[];
  attributes: { [key: string]: DocumentAttributes }[];
  labIds: string[];
}
